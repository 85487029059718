import { Item, BookingInfo } from "../cartStore";

const createCartSlice = (set: any) => ({
  items: [],
  totalAmount: 0,
  bookingInfo: { day: "", slot: "", selectedDay: new Date() },
  addItem: (item: Item) => {
    const updateState = (state: any) => {
      const updatedTotalAmount = state.totalAmount + item.price;

      const existingCartItemIndex = state.items.findIndex(
        (prevItem: Item) => prevItem.id === item.id
      );

      const existingCartItem = state.items[existingCartItemIndex];
      let updatedItems;

      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          amount: existingCartItem.amount + item.amount,
        };
        updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;
      } else {
        updatedItems = state.items.concat(item);
      }

      return {
        items: updatedItems,
        totalAmount: updatedTotalAmount,
        bookingInfo: state.bookingInfo,
      };
    };

    set((state: any) => updateState(state));
  },
  addBooking: (booking: BookingInfo) => {
    const updateState = (state: any) => {
      return {
        items: [...state.items],
        totalAmount: state.totalAmount,
        bookingInfo: {
          day: booking.day,
          slot: booking.slot,
          selectedDay: booking.selectedDay,
        },
      };
    };

    set((state: any) => updateState(state));
  },
  removeItem: (id: string) => {
    const updateState = (state: any) => {
      const updatedItems = state.items.filter((item: Item) => item.id !== id);

      const removeItemAmount = state.items.filter(
        (item: Item) => item.id === id
      );

      const updatedPrice =
        state.totalAmount -
        removeItemAmount[0].price * removeItemAmount[0].amount;

      return {
        items: updatedItems,
        totalAmount: updatedPrice,
        bookingInfo: state.bookingInfo,
      };
    };
    set((state: any) => updateState(state));
  },
});

export default createCartSlice;

import NumberFormat from "react-number-format";
import toast, { Toaster } from "react-hot-toast";
import useCartStore from "../../store/cart/cartStore";
import useStepperStore from "../../store/stepper/stepperStore";
import { useTranslation } from "react-i18next";

export interface ServiceItemProps {
  id: string;
  name: string;
  description: string;
  price: number;
  imgUrl: string;
  duration: number | undefined;
  wazeLink: string;
}

const ServiceItem = ({
  id,
  name,
  description,
  price,
  imgUrl,
  duration,
}: ServiceItemProps) => {
  const { addItem } = useCartStore((state) => ({ addItem: state.addItem }));
  const { setIsBookingDisabled } = useStepperStore(
    (state: { setIsBookingDisabled: any }) => ({
      setIsBookingDisabled: state.setIsBookingDisabled,
    })
  );
  const { t } = useTranslation();
  const itemPrice = `${price.toFixed(2)}`;
  const imgPath = imgUrl || "images/VanityIMG.png";

  const addToCartHandler = (e: any) => {
    e.preventDefault();

    toast.success("El servicio ha sido agregado al carrito.");

    setIsBookingDisabled(false);
    addItem({
      id,
      name,
      amount: 1,
      price: +itemPrice,
      duration,
    });
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
          },
        }}
      />
      <div className="list-items items-center justify-between mx-auto border-b py-4 dark:bg-gray-100 rounded-md my-1 px-4 mt-4">
        <div className="flex flex-row items-center space-x-4">
          <img
            src={imgPath}
            alt="default img"
            className="h-12 w-12 rounded-full"
          />
          <div className="overflow-hidden">
            <h1 className="tracking-tight ">{name}</h1>
            <p className="service-description text-gray-500 truncate ... font-light dark:text-gray-500 font-small sm:font-medium">
              {description}
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <span
            className="mx-2 service-item-price"
            style={{ fontSize: "1.2rem" }}
          >
            <NumberFormat
              value={price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"¢"}
            />
          </span>
          <button
            onClick={addToCartHandler}
            style={{ backgroundColor: "#84c442" }}
            className="rounded-md px-4 py-1 text-sm font-semibold hover:bg-green-800 hover:text-white transition duration-200 ease-in-out"
          >
            + {t("add_to_cart")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ServiceItem;

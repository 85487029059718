import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { createAppointment, createCustomer } from "../../API/api-calls";
import { AppointmentPayload, initialFormData } from "../../utils/utils";
import Cart from "../Cart";
import CustomerForm from "./CustomerForm";
import useStepperStore from "../../store/stepper/stepperStore";

const Payment = ({
  token,
  handleClick,
}: {
  token: string;
  handleClick: any;
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [successBook, setSuccessBook] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [payload, setPayload] = useState<AppointmentPayload | null>(null);
  const { setIsConfirmationDisabled } = useStepperStore(
    (state: { setIsConfirmationDisabled: any }) => ({
      setIsConfirmationDisabled: state.setIsConfirmationDisabled,
    })
  );
  const { t } = useTranslation();

  const customer = {
    first_name: formData.billToFirstName,
    last_name: formData.billToLastName,
    email_id: formData.billToEmail,
    cell_phone: formData.billToTelephone,
    address: "Heredia",
  };

  const { data: customerKey, isLoading: isLoadingCustomer } = useQuery(
    ["createCustomer", token, customer],
    createCustomer,
    {
      enabled: formSubmitted,
    }
  );

  const { data: dataAppoinment, isLoading: isAppointmentLoading } = useQuery(
    ["createCustomer", token, { ...payload, customer_key: customerKey }],
    createAppointment,
    {
      enabled: formSubmitted && !!customerKey && !!payload,
    }
  );

  useEffect(() => {
    // setIsPaymentDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataAppoinment?.msg === "Appointment created successfully") {
      // handleClick("next");
      setSuccessBook(true);
      setIsConfirmationDisabled(false);
    }

    if (
      dataAppoinment?.msg &&
      dataAppoinment?.msg !== "Appointment created successfully"
    ) {
      setFormError(true);
    }
  }, [dataAppoinment, isAppointmentLoading, setIsConfirmationDisabled]);

  const goToNextPage = (payload: AppointmentPayload, formData: any) => {
    setPayload(payload);
    setFormData(formData);
    setFormSubmitted(true);
  };

  return (
    <>
      <main className="max-w-7xl mx-auto pb-10 px-4 sm:px-3 lg:px-4">
        <div className="bg-gray-50 px-3 lg:px-4 py-4">
          <Cart />
        </div>
        <div className="bg-gray-50 max-w-2xl mx-auto lg:max-w-none px-3 lg:px-4 mt-5 py-4">
          <h2 className="text-lg font-medium text-gray-900">
            {t("personal_information")}
          </h2>
          <CustomerForm
            token={token}
            goToNextPage={goToNextPage}
            isAppointmentLoading={isAppointmentLoading}
            isLoadingCustomer={isLoadingCustomer}
          />
          {formError && (
            <p className="text-center mt-4 text-red-600">
              Hubo un error al agendar la cita, intenta de nuevo con otra fecha
              u hora.
            </p>
          )}
          {successBook && (
            <p className="text-center mt-4 text-green-600">
              Sus datos han sido enviados.
            </p>
          )}
        </div>
      </main>
    </>
  );
};

export default Payment;

import React, { useEffect, useState, useRef } from "react";

export interface StepperProps {
  steps: string[];
  currentStep: number;
}

export interface StepState {
  description: string;
  completed: boolean;
  highlighted: boolean;
  selected: boolean;
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  const [newStep, setNewStep] = useState([] as any);
  const stepRef = useRef() as React.MutableRefObject<any>;

  const updatedState = (stepNumber: number, steps: any) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      } else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) => {
      const stepObj = {} as StepState;
      stepObj.description = step;
      stepObj.completed = false;
      stepObj.highlighted = index === 0 ? true : false;
      stepObj.selected = index === 0 ? true : false;
      return stepObj;
    });

    stepRef.current = stepsState;
    const current = updatedState(currentStep - 1, stepRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const displaySteps = newStep.map((step: StepState, index: number) => {
    return (
      <div
        key={index}
        className={
          index !== newStep.length - 1
            ? "w-full flex items-center"
            : "flex items-center"
        }
      >
        <div className="relative flex flex-col items-center text-teal-600">
          <div
            className={`rounded-full transition duration-500 ease-in-out border-2 
          border-gray-300 h-8 w-8 sm:h-12 sm:w-12 flex items-center justify-center py-3 ${
            step.selected
              ? "bg-green-600 text-white font-bold border border-green-600"
              : ""
          }`}
          >
            {step.completed ? (
              <span className="text-white font-bold text-xl">&#10003; </span>
            ) : (
              index + 1
            )}
          </div>
          <div
            className={`absolute top-0 text-center mt-16 w-32 text-xs font-small sm:font-medium md:uppercase ${
              step.highlighted ? "text-gray-900" : "text-gray-400"
            }`}
          >
            {step.description}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 transtion duration-500 ease-in-out ${
            step.completed ? "border-green-600" : "border-gray-300"
          }`}
        ></div>
      </div>
    );
  });

  return (
    <div className="mx-4 p-4 flex justify-between items-center">
      {displaySteps}
    </div>
  );
};

export default Stepper;

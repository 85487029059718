import { useState } from "react";
import { config } from "../../helpers/constants";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useCartStore from "../../store/cart/cartStore";
import PaymentOptionButton from "./PaymentOptionButton";
import { format } from "date-fns";
import { initialFormData } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import "./CustomerForm.styles.css";
import MyFileUpload from "./MyFileUpload";

const CustomerForm = ({
  token,
  goToNextPage,
  isAppointmentLoading = false,
  isLoadingCustomer = false,
}: {
  token: string;
  goToNextPage: any;
  isAppointmentLoading: boolean;
  isLoadingCustomer: boolean;
}) => {
  const [formData, setFormData] = useState(initialFormData);
  const [checked, setChecked] = useState("local");
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [comprobante, setComprobante] = useState(
    "https://thumbsnap.com/i/U1i8snnp.jpg"
  );
  const { items, bookingInfo } = useCartStore((state) => ({
    items: state.items,
    bookingInfo: state.bookingInfo,
  }));
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const payload = {
    staff_key: config.staffKeyManolo,
    service_key: items[0].id,
    start_time: startTime,
    end_time: endTime,
    comment: "",
    label: checked === "local" ? "Paga en local" : "Pagado por sinpe",
  };

  const sendEmail = () => {
    emailjs
      .send(
        "service_ysbwddc",
        "template_2s2bx4m",
        {
          from_name: `${formData.billToFirstName} ${formData.billToLastName}`,
          to_name: "Lis",
          from_cel: formData.billToTelephone,
          comprobante,
        },
        "9xCLDsZzS5hwSohCj"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  async function copyText(type: string) {
    try {
      const textToCopy =
        type === "numero"
          ? config.sinpeMovil
          : type === "nombre"
          ? config.sinpeTitular
          : type === "cedula"
          ? config.sinpeCedula
          : "";

      await window.navigator.clipboard.writeText(textToCopy);
      toast.success("Se ha copiado al portapapeles");
    } catch (error) {
      console.error("Failed to copy text: ", error);
      toast.error("Hubo un error al copiar el text");
    }
  }

  const handleImageUpload = (img: string) => {
    setComprobante(img);
  };

  const onSubmit = (data: any) => {
    const slots = bookingInfo.slot.split(".");
    const selectedDay = bookingInfo.selectedDay;
    const selectedDayWithHours = new Date(selectedDay).setHours(
      +slots[0],
      +slots[1]
    );
    const startTime = format(selectedDayWithHours, "yyyy-MM-dd'T'HH:mm");
    setStartTime(startTime);

    const date = new Date(startTime);

    const endTime = format(
      new Date(selectedDayWithHours).setMinutes(
        date.getMinutes() + (items[0].duration ?? 60)
      ),
      "yyyy-MM-dd'T'HH:mm"
    );
    setEndTime(endTime);

    // if (formData.bookingOption === "online") {
    //   processOnlinePayment();
    // }

    if (formData.bookingOption === "sinpe") {
      sendEmail();
    }

    reset();

    goToNextPage(
      {
        ...payload,
        start_time: startTime,
        end_time: endTime,
      },
      formData
    );

    setFormData(initialFormData);
  };

  const handleChange = (e: any) => {
    // console.log("formData: ", formData);
    if (e.target.value === "local") {
      setChecked("local");
    }
    if (e.target.value === "sinpe") {
      setChecked("sinpe");
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      className="lg:grid lg:grid-cols-1 lg:gap-x-12 xl:gap-x-16"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Toaster
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
          },
        }}
      />
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <div>
          <label
            htmlFor="billToFirstName"
            className="block text-sm font-medium text-gray-700"
          >
            Nombre
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="billToFirstName"
              value={formData.billToFirstName}
              onInput={handleChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("billToFirstName", {
                required: true,
                pattern:
                  /^[a-zA-ZÀ-ÿ\u00f1\u00d1 _]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 _]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 _]+$/,
                maxLength: 30,
              })}
            />
            {errors.billToFirstName && (
              <p className="text-red-600">
                El nombre tiene un formato incorrecto.
              </p>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="billToLastName"
            className="block text-sm font-medium text-gray-700"
          >
            Apellido
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="billToLastName"
              value={formData.billToLastName}
              onInput={handleChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("billToLastName", {
                required: true,
                pattern:
                  /^[a-zA-ZÀ-ÿ\u00f1\u00d1 _]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 _]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 _]+$/,
                maxLength: 30,
              })}
            />
            {errors.billToLastName && (
              <p className="text-red-600">
                El apellido tiene un formato incorrecto.
              </p>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="billToEmail"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="billToEmail"
              value={formData.billToEmail}
              onInput={handleChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("billToEmail", {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.billToEmail && (
              <p className="text-red-600">
                El correo está vacío o tiene un formato incorrecto.
              </p>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="billToTelephone"
            className="block text-sm font-medium text-gray-700"
          >
            Teléfono
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="billToTelephone"
              value={formData.billToTelephone}
              onInput={handleChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              {...register("billToTelephone", {
                required: false,
                minLength: 8,
                pattern:
                  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
              })}
            />
            {errors.billToTelephone && (
              <p className="text-red-600">
                El formato del número es incorrecto.
              </p>
            )}
          </div>
        </div>
      </div>
      <h2 className="mt-5">Selecciona el método de pago</h2>
      <div className="grid gap-6 w-full md:grid-cols-2 mt-3">
        <PaymentOptionButton
          checked={checked === "local"}
          handleChange={(e) => handleChange(e)}
          register={register}
          inputValue="local"
          title="Pago en el local"
          description="En efectivo, tarjeta de crédito, débito"
        />
        <PaymentOptionButton
          checked={checked === "sinpe"}
          handleChange={(e) => handleChange(e)}
          register={register}
          inputValue="sinpe"
          title="Pago SINPE Móvil"
          description="Agenda tu cita con pago por SINPE Móvil"
        />
      </div>
      {errors.bookingOption && (
        <p className="text-red-600">Por favor seleccionar el tipo de pago.</p>
      )}
      {formData.bookingOption === "sinpe" && (
        <div className="mt-5">
          <h3 className="bg-green-500 text-white p-3">
            1. Realizar transferencia
          </h3>
          <div className="mt-4 mb-4">
            <p>Sinpe Móvil (CRC)</p>
            <div className="flex mt-4 border-y border-y-slate-300 py-3">
              <span className="flex-1">
                <p className="font-semibold">Número de teléfono</p>
                <p>{config.sinpeMovil}</p>
              </span>
              <button
                className="flex-none bg-gray-500 text-white uppercase py-2 px-4 rounded-md font-semibold cursor-pointer mx-auto
     hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
                type="button"
                onClick={() => copyText("numero")}
              >
                COPIAR
              </button>
            </div>
            <div className="flex mt-4 border-y border-y-slate-300 py-3">
              <div className="flex-1">
                <p className="font-semibold">Nombre del titular</p>
                <p>{config.sinpeTitular}</p>
              </div>

              <button
                className="flex-none bg-gray-500 text-white uppercase py-2 px-4 rounded-md font-semibold cursor-pointer mx-auto
     hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
                type="button"
                onClick={() => copyText("nombre")}
              >
                COPIAR
              </button>
            </div>
            {/* <div className="flex mt-4 border-y border-y-slate-300 py-3">
              <div className="flex-1">
                <p className="font-semibold">Identificación del titular</p>
                <p>{config.sinpeCedula}</p>
              </div>

              <button
                className="flex-none bg-gray-500 text-white uppercase py-2 px-4 rounded-md font-semibold cursor-pointer mx-auto
     hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
                type="button"
                onClick={() => copyText("cedula")}
              >
                COPIAR
              </button>
            </div> */}
          </div>
          <h3 className="bg-green-500 text-white p-3 mt-5">
            2. Adjuntar imagen de comprobante
          </h3>
          <div className="upload">
            <MyFileUpload
              handleImageUpload={(img: string) => handleImageUpload(img)}
            />
          </div>
        </div>
      )}
      <button
        type="submit"
        className="bg-green-500 text-white uppercase py-2 px-4 rounded-md font-semibold cursor-pointer mt-6 w-full sm:w-3/6 mx-auto
     hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out"
      >
        {" "}
        {(isAppointmentLoading || isLoadingCustomer) && t("please_wait")}
        {!isAppointmentLoading && !isLoadingCustomer && t("book")}
        {/* {checked === "local" ? "Agendar cita" : "Realizar Pago"} */}
      </button>
    </form>
  );
};

export default CustomerForm;

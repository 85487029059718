import { useEffect } from "react";
import { useQueryClient } from "react-query";
// import { createAppointment } from "../../API/api-calls";
// import { config } from "../../helpers/constants";

const Confirmation = () => {
  const queryClient = useQueryClient();
  // useEffect(() => {
  //   const appointmentIsCreated = sessionStorage.getItem("appointment");
  //   if (!appointmentIsCreated) {
  //     const stringifyStorage = sessionStorage.getItem(
  //       "bookingStorage"
  //     ) as string;
  //     const storageObj = JSON.parse(stringifyStorage);
  //     const { token, customerKey, serviceKey, startTime, endTime } = storageObj;

  //     const payload = {
  //       staff_key: config.staffKey,
  //       service_key: serviceKey,
  //       customer_key: customerKey,
  //       start_time: startTime,
  //       end_time: endTime,
  //       comment: "",
  //       label: "",
  //     };

  //     createAppointment({ token, payload });
  //   }
  // }, []);

  useEffect(() => {
    localStorage.removeItem("global");
    queryClient.clear();
    queryClient.removeQueries("services");
  }, [queryClient]);

  return (
    <div className="container mt-4 mb-8">
      <h1 className="text-center font-medium">Confirmación</h1>

      <p className="mt-5 text-center">
        Gracias por su confianza, su cita ha sido agendada. Revisa tu correo con
        la confirmación de la fecha y hora agendada.
      </p>
    </div>
  );
};

export default Confirmation;

import { ChangeEventHandler } from "react";

type PaymentOptionButtonProps = {
  checked: boolean;
  handleChange: ChangeEventHandler;
  register: (name: string, obj: any) => {};
  inputValue: string;
  title: string;
  description: string;
  isDisabled?: boolean;
};

const PaymentOptionButton = ({
  checked,
  handleChange,
  register,
  inputValue,
  title,
  description,
  isDisabled = false,
}: PaymentOptionButtonProps) => {
  return (
    <div className="flex items-center pl-4 rounded border border-gray-200">
      <input
        id={inputValue}
        name="bookingOption"
        type="radio"
        {...register("bookingOption", {
          required: true,
        })}
        value={inputValue}
        onChange={handleChange}
        disabled={isDisabled}
        checked={checked}
        className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2"
      />
      <label
        htmlFor="bordered-radio-1"
        className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-500"
      >
        {title}
        <br />
        {description}
      </label>
    </div>
  );
};

export default PaymentOptionButton;

import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import createUserSlice from "./slices/createServicesSlice";

type ServiceStore = {
  token: string;
  removeToken: () => void;
  fetchToken: () => void;
};

const servicesStore = (set: any, get: any) =>
  ({
    ...createUserSlice(set),
    removeToken: () => {
      set(() => ({
        token: "",
      }));
    },
  } as ServiceStore);

const useServicesStore = create(
  devtools(
    persist(servicesStore, {
      name: "global",
    })
  )
);

export default useServicesStore;

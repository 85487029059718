import { useState } from "react";
import CartModal from "../Modal/CartModal";
import classes from "./Header.module.css";
import HeaderCartButton from "./HeaderCartButton";

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const showCartHandler = () => {
    // Open chart modal
    setShowModal(true);
  };

  return (
    <header className={classes.header}>
      <h1>
        <a href="/">
          <img
            src="https://res.cloudinary.com/dm8ee33af/image/upload/v1542217412/logo_vanity_white_new.jpg"
            alt="Logo Vanity Spa"
          />
        </a>
      </h1>
      <HeaderCartButton onClick={showCartHandler} />
      {showModal && (
        <CartModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </header>
  );
};

export default Header;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import TermsModal from "../Modal/TermsModal";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const showTerms = () => {
    setShowModal(true);
  };

  return (
    <footer className="mt-3">
      <p className="text-center text-xs text-gray-700 ">
        {t("all_rights_reserved")}
      </p>
      <p className="text-center text-xs text-gray-700 underline">
        {" "}
        <button onClick={showTerms} className="underline">
          Términos y condiciones
        </button>
      </p>
      <p className="text-center text-xs text-gray-700 ">
        {t("built_by")}{" "}
        <a
          href="https://www.linkedin.com/in/luisrojascr/"
          target="_blank"
          title="Luis Rojas Developer"
          rel="noreferrer"
        >
          Luis Rojas{" "}
        </a>
        {/* y{" "}
        <a
          href="https://www.linkedin.com/in/jose-rojas-dev/"
          target="_blank"
          title="José Rojas Developer"
          rel="noreferrer"
        >
          José Mario Rojas
        </a> */}
      </p>
      {showModal && (
        <TermsModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </footer>
  );
};

export default Footer;

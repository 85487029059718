import { memo } from "react";
import ServiceItem from "./ServiceItem";
import { useQuery } from "react-query";
import { fetchCategories, fetchServices } from "../../API/api-calls";
import { useTranslation } from "react-i18next";

const Services = memo(({ token }: { token: string }) => {
  // const { sucursal } = useStepperStore((state) => ({
  //   sucursal: state.sucursal,
  // }));
  const {
    data: allServices,
    isError: isServicesError,
    isLoading: isLoadingServices,
  } = useQuery(["services", token], fetchServices);
  const { t } = useTranslation();

  // data: categories,
  const { isError: isCategoriesError, isLoading: isLoadingCategories } =
    useQuery(["categories", token], fetchCategories);

  if (isServicesError || isCategoriesError) {
    return (
      <div className="container mt-4 mb-8">
        <div className="mt-4">
          <h1>{t("select_a_service")}</h1>
          <div className="list-container">
            <p>{t("there_was_an_error_load_again")}</p>
          </div>
        </div>
      </div>
    );
  }

  if (isLoadingServices || isLoadingCategories) {
    return (
      <div className="container mt-4 mb-8">
        <div className="mt-4">
          <h1>{t("select_a_service")}</h1>
          <div className="list-container">
            <p>{t("loading_services")}...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4 mb-8">
      <div className="mt-4">
        <h2 className="font-bold ">{t("select_a_service")}</h2>
        <p className="mt-5">
          Estamos actualizando los servicios en nuestra aplicación.
        </p>
        <p>Mientras tanto puedes escribirnos a nuestro WhatsApp: 6585-5040.</p>
        {/* TODO: Mejorar el listado de categorias
        <div className="list-container">
          {categories &&
            categories.length &&
            categories
              .filter((category) => category.categoryName !== "All Services")
              .map((category, i: number) => (
                <div key={i} className="mt-5">
                  <h3 className="mb-4">{category.categoryName}</h3>
                  {allServices &&
                    allServices
                      .filter((service) =>
                        category.serviceIdList.includes(service.key)
                      )
                      .map((service) => (
                        <ServiceItem
                          key={service.key}
                          id={service.key}
                          name={service.service_name}
                          description={service.description}
                          price={service.cost}
                          imgUrl={service.image_url}
                          duration={service.duration}
                          wazeLink={service.waze_link}
                        />
                      ))}
                </div>
              ))}
        </div> */}
        <div className="list-container">
          <div className="mt-5">
            {allServices &&
              allServices.map((service) => (
                <ServiceItem
                  key={service.key}
                  id={service.key}
                  name={service.service_name}
                  description={service.description}
                  price={service.cost}
                  imgUrl={service.image_url}
                  duration={service.duration}
                  wazeLink={service.waze_link}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Services;

// const dev = {
//   url: {
//     API_URL: "https://developer.setmore.com/api/v1",
//   },
//   tilopayURL: {
//     API_URL: "https://app.tilopay.com/api/v1",
//   },
//   key: "8328-5823-9955-7022-5682",
//   redirectLink: "http://localhost:3000/confirmation",
//   currency: "CRC",
//   defaultZipCode: "10001",
//   staffKey: "recfac768e6492ca15564f82a3bc6946",
// };

const prod = {
  url: {
    API_URL: "https://developer.setmore.com/api/v1",
  },
  tilopayURL: {
    API_URL: "https://app.tilopay.com/api/v1",
  },
  key: "8328-5823-9955-7022-5682",
  redirectLink: "https://agenda.vanityspacostarica.com/confirmation",
  currency: "CRC",
  defaultZipCode: "10001",
  staffKeyManolo: "recfac768e6492ca15564f82a3bc6946",
  staffKeyVila: "r06dc1664297117816",
  sinpeMovil: "63855040",
  sinpeTitular: "Liseth Pérez",
  sinpeCedula: "1111122222",
};

export const config = prod;

import { config } from "../helpers/constants";

export const initialFormData = {
  billToFirstName: "",
  billToLastName: "",
  billToEmail: "",
  billToTelephone: "",
  bookingOption: "local",
};

export const paymentPayloadObj = {
  redirect: config.redirectLink,
  key: config.key,
  amount: 0,
  currency: config.currency,
  billToAddress: "San Jose",
  billToAddress2: "Catedral",
  billToState: "SJ",
  billToZipPostCode: config.defaultZipCode,
  orderNumber: "",
  capture: "1",
  subscription: "0",
  platform: "api",
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export type AppointmentPayload = {
  staff_key: string;
  service_key: string;
  customer_key: string;
  start_time: string;
  end_time: string;
  comment?: string;
  label: string;
};

// const paymentAmount = items.reduce((acc, item) => {
//   return acc + item.price;
// }, 0);
// const orderNum = getRandomInt(1, 10000000);
// const paymentPayload = {
//   ...paymentPayloadObj,
//   redirect: `${paymentPayloadObj.redirect}`,
//   amount: paymentAmount,
//   orderNumber: orderNum,
//   ...formData,
// };

// const { data: processedPayment, isLoading: isLoadingPayment } = useQuery(
//   ["createCustomer", paymentToken, paymentPayload],
//   processPayment,
//   {
//     enabled: !!startTime && !!endTime && !!paymentToken,
//   }
// );

// const processOnlinePayment = () => {
//   const loginPayload = {
//     email: process.env.REACT_APP_TILOPAY_EMAIL,
//     password: process.env.REACT_APP_TILOPAY_PASS,
//   };

//   // const bookingStorageObj = {
//   //   token,
//   //   // customerKey,
//   //   serviceKey: items[0].id,
//   //   startTime,
//   //   endTime,
//   // };

//   try {
//     fetch(`${config.tilopayURL.API_URL}/login`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//       body: JSON.stringify(loginPayload),
//     })
//       .then((response) => response.json())
//       .then((res) => {
//         // setPaymentToken(res?.access_token);
//         // if (processedPayment) {
//         //   sessionStorage.setItem(
//         //     "bookingStorage",
//         //     JSON.stringify(bookingStorageObj)
//         //   );
//         //   openLink(processedPayment, false);
//         // }
//       });
//   } catch (e) {
//     console.error(e);
//   }
// };

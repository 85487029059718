import create from "zustand";
import { devtools, persist } from "zustand/middleware";

type StepperStore = {
  sucursal: string;
  // disabled: boolean;
  isBookingDisabled: boolean;
  isPaymentDisabled: boolean;
  isConfirmationDisabled: boolean;
  setIsBookingDisabled: (value: boolean) => void;
  setIsPaymentDisabled: (value: boolean) => void;
  setIsConfirmationDisabled: (value: boolean) => void;
  addSucursal: (name: string) => void;
};

const stepperStore = (set: any) =>
  ({
    // disabled: true,
    isBookingDisabled: true,
    isPaymentDisabled: true,
    isConfirmationDisabled: true,
    sucursal: "",
    setIsBookingDisabled: (disabled) =>
      set((state: any) => ({ ...state, isBookingDisabled: disabled })),
    setIsPaymentDisabled: (disabled) =>
      set((state: any) => ({ ...state, isPaymentDisabled: disabled })),
    setIsConfirmationDisabled: (disabled) =>
      set((state: any) => ({ ...state, isConfirmationDisabled: disabled })),
    addSucursal: (name) => set((state: any) => ({ ...state, sucursal: name })),
  } as StepperStore);

const useStepperStore = create(
  devtools(
    persist(stepperStore, {
      name: "global",
    })
  )
);

export default useStepperStore;

import { config } from "../../../helpers/constants";

const createServicesSlice = (set: any) => ({
  token: "",
  fetchToken: async () => {
    const tokenRes = await fetch(
      `${config.url.API_URL}/o/oauth2/token?refreshToken=${process.env.REACT_APP_REFRESH_TOKEN}`
    );
    const resData = await tokenRes.json();
    const token = resData.data?.token.access_token;

    set({ token });
  },
});

export default createServicesSlice;

import { useTranslation } from "react-i18next";
import useStepperStore from "../store/stepper/stepperStore";

export interface StepperControlProps {
  handleClick: any;
  steps: string[];
  currentStep: number;
}

const StepperControl = ({
  handleClick,
  currentStep,
  steps,
}: StepperControlProps) => {
  const { isBookingDisabled, isPaymentDisabled, isConfirmationDisabled } =
    useStepperStore((state) => ({
      isBookingDisabled: state.isBookingDisabled,
      isPaymentDisabled: state.isPaymentDisabled,
      isConfirmationDisabled: state.isConfirmationDisabled,
    }));
  const { t } = useTranslation();
  const isDisabled =
    currentStep === 1
      ? isBookingDisabled
      : currentStep === 2
      ? isPaymentDisabled
      : currentStep === 3
      ? isConfirmationDisabled
      : true;

  return (
    <>
      <div className="container flex justify-around mt-4 mb-8">
        <button
          onClick={() => handleClick()}
          className={`bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer
     border-2 border-slate-300 hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out ${
       currentStep === 1 ? "opacity-50 cursor-not-allowed" : ""
     }`}
        >
          {t("back")}
        </button>

        {currentStep && (
          <button
            onClick={() => handleClick("next")}
            disabled={isDisabled}
            className={`bg-green-500 text-white uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer
     hover:bg-slate-700 hover:text-white transition duration-200 ease-in-out ${
       isDisabled ? "opacity-50 cursor-not-allowed" : ""
     } `}
          >
            {currentStep === steps.length - 1 ? t("confirm") : t("next")}
          </button>
        )}
      </div>
      <div className="px-3">
        <p className="text-xs text-center">
          ¿Algún problema con la aplicación? Escríbenos:{" "}
          <a
            href="https://wa.link/yoyfy0"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            65855040
          </a>
        </p>
      </div>
    </>
  );
};

export default StepperControl;

interface IModalProps {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
}

const TermsModal = ({ showModal, setShowModal }: IModalProps) => {
  return (
    <>
      {showModal ? (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                <h3 className="text-3xl font=semibold text-black">
                  Términos y Condiciones
                </h3>
                <button
                  className="bg-transparent border-0 text-black float-right"
                  onClick={() => setShowModal(false)}
                >
                  <span className="text-black opacity-7 h-6 w-6 text-xl block py-0 rounded">
                    x
                  </span>
                </button>
              </div>
              <div className="relative p-6 flex-auto text-black">
                <p className="mb-3">
                  1. Para agendar el servicio debe completar los pasos hasta
                  llegar al paso de confirmación
                </p>
                <p className="mb-3">
                  2. Se puede agendar el servicio reservando desde la aplicación
                  y pagar en el local ya sea en efectivo o tarjeta de
                  débito/crédito
                </p>
                <p className="mb-3">
                  3. Al seleccionar la opción de pago por SINPE móvil debe
                  adjuntar el comprobante de pago. La cita se va a reservar
                  temporalmente y una vez que se verifique el pago, la cita será
                  confirmada
                </p>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TermsModal;

import { useState } from "react";

interface IMyFileUploadProps {
  handleImageUpload: (img: string) => void;
}

const MyFileUpload = ({ handleImageUpload }: IMyFileUploadProps) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  const changeHandler = (event: any) => {
    setTimeout(() => {
      handleSubmission(event.target.files[0]);
    }, 1000);
  };

  const handleSubmission = (selectedFile: any) => {
    const formData = new FormData();

    if (!selectedFile) {
      alert("Suba una imagen primero");
      return;
    }

    formData.append("key", "0000494b6a4a2ce03423af4ede405319");
    formData.append("media", selectedFile);

    fetch("https://thumbsnap.com/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        handleImageUpload(result.data.media);
        setUploadedImage(result.data.media);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {uploadedImage ? (
        <img src={uploadedImage} alt="comprobante" />
      ) : (
        <img
          src="https://icon-library.net/images/image-placeholder-icon/image-placeholder-icon-6.jpg"
          aria-hidden
          alt="Upload image"
        />
      )}

      <div>
        <input
          type="file"
          id="image_uploads"
          name="image_uploads"
          onChange={changeHandler}
          className="custom-file-input"
          accept="image/*"
        />
      </div>
    </>
  );
};

export default MyFileUpload;

import { MouseEventHandler, useEffect, useState } from "react";
import CartIcon from "../CartIcon";
import classes from "./HeaderCartButton.module.css";
import useCartStore from "../../store/cart/cartStore";
import { useTranslation } from "react-i18next";

const HeaderCartButton = (props: {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const [btnIsHighlighted, setBtnIsHighlighted] = useState(false);
  const { t } = useTranslation();
  const { items } = useCartStore((state) => ({
    items: state.items,
  }));

  const numberOfCartItems = items.reduce((curNumber, item) => {
    return curNumber + item.amount;
  }, 0);

  const btnClasses = `${classes.button} ${
    btnIsHighlighted ? classes.bump : ""
  }`;

  useEffect(() => {
    if (items.length === 0) {
      return;
    }
    setBtnIsHighlighted(true);

    const timer = setTimeout(() => {
      setBtnIsHighlighted(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [items]);

  return (
    <button className={btnClasses} onClick={props.onClick}>
      <span className={classes.icon}>
        <CartIcon />
      </span>
      <span>{t("cart")}</span>
      <span className={classes.badge}>{numberOfCartItems}</span>
    </button>
  );
};

export default HeaderCartButton;

import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isToday,
  isSameMonth,
  startOfToday,
  parse,
  isBefore,
} from "date-fns";
import es from "date-fns/locale/es";
import { Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchBookingSlots } from "../../API/api-calls";
import useCartStore from "../../store/cart/cartStore";
import useStepperStore from "../../store/stepper/stepperStore";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];

const Booking = ({ token }: { token: string }) => {
  let navigate = useNavigate();
  const { setIsPaymentDisabled } = useStepperStore(
    (state: { setIsPaymentDisabled: any }) => ({
      setIsPaymentDisabled: state.setIsPaymentDisabled,
    })
  );
  let today = startOfToday();
  let tomorrow = add(today, { days: 1 });
  const { addBooking } = useCartStore((state) => ({
    addBooking: state.addBooking,
  }));
  const { items } = useCartStore((state) => ({
    items: state.items,
  }));
  let [selectedDay, setSelectedDay] = useState(tomorrow);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let [selectedSlot, setSelectedSlot] = useState("");
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  const { t } = useTranslation();

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  const handleBooking = (e: any, item: string) => {
    setSelectedSlot(item);
    setIsPaymentDisabled(false);
    addBooking({
      day: format(selectedDay, "eeee dd MMM, yyy", { locale: es }),
      slot: item,
      selectedDay,
    });
  };

  console.log("items: ", items);
  if (!items) {
    navigate("/");
  }

  const {
    data: slots,
    isError,
    isLoading,
  } = useQuery(["slots", token, selectedDay, items[0]?.id], fetchBookingSlots);

  useEffect(() => {
    // setIsPaymentDisabled(true);
    console.log("token: ", token);
    if (!token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-16">
      <div className="max-w-md px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6">
        <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
          <div className="md:pr-14">
            <div className="flex items-center">
              <h2 className="flex-auto font-semibold text-gray-900">
                {format(firstDayCurrentMonth, "MMMM yyyy")}
              </h2>
              <button
                type="button"
                onClick={previousMonth}
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Mes anterior</span>
                <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              <button
                onClick={nextMonth}
                type="button"
                className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Mes siguiente</span>
                <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
            <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
              <div>D</div>
              <div>L</div>
              <div>M</div>
              <div>M</div>
              <div>J</div>
              <div>V</div>
              <div>S</div>
            </div>
            <div className="grid grid-cols-7 mt-2 text-sm">
              {days.map((day, dayIdx) => (
                <div
                  key={day.toString()}
                  className={classNames(
                    dayIdx === 0 && colStartClasses[getDay(day)],
                    "py-1.5"
                  )}
                >
                  <button
                    type="button"
                    onClick={() => setSelectedDay(day)}
                    className={classNames(
                      isEqual(day, selectedDay) && "text-white",
                      !isEqual(day, selectedDay) &&
                        isToday(day) &&
                        "text-red-500",
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        "text-gray-900",
                      !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        "text-gray-400",
                      isEqual(day, selectedDay) && isToday(day) && "bg-red-500",
                      isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        "bg-gray-900",
                      !isEqual(day, selectedDay) && "hover:bg-gray-200",
                      (isEqual(day, selectedDay) || isToday(day)) &&
                        "font-semibold",
                      "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                    )}
                    disabled={isToday(day) || isBefore(day, today)}
                  >
                    <time dateTime={format(day, "yyyy-MM-dd")}>
                      {format(day, "d", { weekStartsOn: 1 })}
                    </time>
                  </button>
                </div>
              ))}
            </div>
          </div>
          <section className="mt-12 md:mt-0 md:pl-14">
            <h2 className="font-semibold text-gray-900">
              {t("availability_for")}{" "}
              <time dateTime={format(selectedDay, "yyyy-MM-dd")}>
                {format(selectedDay, "eeee dd MMM, yyy", { locale: es })}
              </time>
            </h2>
            <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
              {isError && <p>Hubo un error al cargar las citas disponibles.</p>}
              {isLoading ? (
                <p>Cargando...</p>
              ) : !isError && slots && slots.length > 0 ? (
                <ul
                  className="nav nav-pills flex flex-wrap list-none pl-0 mr-4"
                  role="tablist"
                >
                  {slots.map((item: any, id: Key | null | undefined) => (
                    <li
                      key={id}
                      className="justify-content-start text-start text-center mb-2 mr-3"
                    >
                      <button
                        className={`inline-block my-2 px-6 py-2.5 bg-slate-700 text-white font-medium text-xs 
                                  leading-tight uppercase rounded-full shadow-md hover:bg-green-500 hover:shadow-lg 
                                  ${
                                    selectedSlot === item
                                      ? "active active:bg-green-500"
                                      : ""
                                  } 
                                   transition duration-150 ease-in-out`}
                        onClick={(e) => handleBooking(e, item)}
                      >
                        {" "}
                        {item}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{t("no_slots_available")}.</p>
              )}
            </ol>
          </section>
        </div>
      </div>
      {selectedSlot && (
        <p className="text-center mt-5">
          Su cita será el día{" "}
          <time dateTime={format(selectedDay, "yyyy-MM-dd")}>
            {format(selectedDay, "eeee dd MMM, yyy", { locale: es })}
          </time>{" "}
          a las {selectedSlot}
        </p>
      )}
    </div>
  );
};

export default Booking;

import NumberFormat from "react-number-format";
import { FaTrashAlt } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import useCartStore from "../store/cart/cartStore";
import { useTranslation } from "react-i18next";

interface ICartProps {
  hasTitle?: boolean;
}

const Cart = ({ hasTitle = true }: ICartProps) => {
  const { items, totalAmount, bookingInfo, removeItem } = useCartStore(
    (state) => ({
      items: state.items,
      totalAmount: state.totalAmount,
      bookingInfo: state.bookingInfo,
      removeItem: state.removeItem,
    })
  );
  const { t } = useTranslation();

  // const cartItemsNumber = cartCtx.items.length;
  const handleRemoveItem = (itemId: string) => {
    toast.error("El servicio ha sido eliminado del carrito.");
    removeItem(itemId);
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            background: "#363636",
            color: "#fff",
          },
          error: {
            duration: 3000,
          },
        }}
      />
      <div>
        {hasTitle && (
          <h2 className="mb-4 text-lg font-medium text-gray-90">{t("cart")}</h2>
        )}

        {items.length > 0 ? (
          <ul>
            {items.map((item: any) => (
              <div className="mb-3" key={item.id} style={{ display: "flex" }}>
                <button
                  className="mr-3"
                  style={{ color: "red" }}
                  onClick={() => handleRemoveItem(item.id)}
                >
                  <FaTrashAlt />
                </button>
                <div style={{ flexGrow: "1" }}>
                  {item.name}{" "}
                  <small>
                    (
                    <NumberFormat
                      value={item.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"¢"}
                    />{" "}
                    c/u)
                  </small>{" "}
                  <strong>x{item.amount}</strong>
                </div>
                <span>
                  <NumberFormat
                    value={item.price * item.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"¢"}
                  />
                </span>
              </div>
            ))}
          </ul>
        ) : null}

        {bookingInfo.day && items.length > 0 && (
          <>
            <hr className="mt-3 mb-3" />
            <p>
              <strong>Fecha:</strong> {bookingInfo.day} a las {bookingInfo.slot}
            </p>
          </>
        )}

        {items.length > 0 ? (
          <>
            <hr className="mt-3 mb-3" />
            <div className="flex justify-end">
              <p className="mt-1">
                <span className="mr-2">Total:</span>
                <strong>
                  <NumberFormat
                    value={totalAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"¢"}
                  />
                </strong>
              </p>
            </div>
          </>
        ) : (
          <>
            <hr className="mt-3 mb-3" />
            <p>El carrito está vacio.</p>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;

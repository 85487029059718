import { useEffect, useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";

import StepperControl from "./components/StepperControl";
import Stepper from "./components/Stepper";
import Services from "./components/Steps/Services";
import Booking from "./components/Steps/Booking";
import Payment from "./components/Steps/Payment";
import Confirmation from "./components/Steps/Confirmation";
import { createBrowserHistory } from "history";
import Header from "./components/Header/Header";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import useServicesStore from "./store/services/servicesStore";
import shallow from "zustand/shallow";
import Footer from "./components/Footer/Footer";
import { useTranslation } from "react-i18next";
import useStepperStore from "./store/stepper/stepperStore";
let history = createBrowserHistory();

const queryClient = new QueryClient();

function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [token, fetchToken] = useServicesStore(
    (state) => [state.token, state.fetchToken],
    shallow
  );
  const { isBookingDisabled, isPaymentDisabled, isConfirmationDisabled } =
    useStepperStore(
      (state: {
        isBookingDisabled: boolean;
        isPaymentDisabled: boolean;
        isConfirmationDisabled: boolean;
      }) => ({
        isBookingDisabled: state.isBookingDisabled,
        isPaymentDisabled: state.isPaymentDisabled,
        isConfirmationDisabled: state.isConfirmationDisabled,
      })
    );
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();
  let location = history.location;
  const steps = [t("services"), t("booking"), t("payment"), t("confirmation")];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const displayStep = (step: number) => {
    switch (step) {
      case 1:
        history.push({
          pathname: "/services",
        });
        return <Services token={token} />;
      case 2:
        history.push({
          pathname: "/booking",
        });
        return <Booking token={token} />;
      case 3:
        history.push({
          pathname: "/payment",
        });
        return <Payment token={token} handleClick={handleClick} />;
      case 4:
        history.push({
          pathname: "/confirmation",
        });
        return <Confirmation />;
    }
  };

  const handleClick = (direction: string) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;

    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);

    direction === "next"
      ? displayStep(currentStep + 1)
      : displayStep(currentStep - 1);
  };

  useEffect(() => {
    let currentPath = location.pathname;
    switch (currentPath) {
      case "/services":
        setCurrentStep(1);
        navigate("/services");
        break;
      case "/booking":
        if (isBookingDisabled) {
          setCurrentStep(1);
          currentPath = "/services";
          navigate("/services");
        } else {
          setCurrentStep(2);
          navigate("/booking");
        }
        break;
      case "/payment":
        if (isPaymentDisabled) {
          setCurrentStep(1);
          currentPath = "/services";
          navigate("/services");
        } else {
          setCurrentStep(3);
          navigate("/payment");
        }
        break;
      case "/confirmation":
        if (isConfirmationDisabled) {
          setCurrentStep(1);
          currentPath = "/services";
          navigate("/services");
        } else {
          setCurrentStep(4);
          navigate("/confirmation");
        }
        break;
      default:
        setCurrentStep(1);
    }
  }, [
    location,
    navigate,
    isBookingDisabled,
    isPaymentDisabled,
    isConfirmationDisabled,
  ]);

  useEffect(() => {
    fetchToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header></Header>
      <div className="main-content mx-auto shadow-xl rounded-2xl pb-2 bg-white">
        <div className="flex justify-center mr-3 pt-3">
          <button
            className={`bg-transparent hover:bg-green-600 text-green-700 text-sm font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded-l ${
              i18n.language === "es" ? "bg-green-600 text-white" : ""
            }`}
            onClick={() => changeLanguage("es")}
          >
            ES
          </button>
          <button
            className={`bg-transparent hover:bg-green-600 text-green-700 text-sm font-semibold hover:text-white py-2 px-4 border border-green-600 hover:border-transparent rounded-r ${
              i18n.language === "en" ? "bg-green-600 text-white" : ""
            }`}
            onClick={() => changeLanguage("en")}
          >
            EN
          </button>
        </div>
        <div className="container horizontal mt-2">
          <Stepper steps={steps} currentStep={currentStep} />
        </div>

        <div className="my-8 md:my-10 p-3 md:p-10">
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route
                path="*"
                element={
                  <Suspense fallback={<>...</>}>
                    <Services token={token} />
                  </Suspense>
                }
              />
              <Route
                path="/services"
                element={
                  <Suspense fallback={<>...</>}>
                    <Services token={token} />
                  </Suspense>
                }
              />
              <Route
                path="/booking"
                element={
                  <Suspense fallback={<>...</>}>
                    <Booking token={token} />
                  </Suspense>
                }
              />
              <Route
                path="/payment"
                element={
                  <Suspense fallback={<>...</>}>
                    <Payment token={token} handleClick={handleClick} />
                  </Suspense>
                }
              />
              <Route
                path="/confirmation"
                element={
                  <Suspense fallback={<>...</>}>
                    <Confirmation />
                  </Suspense>
                }
              />
            </Routes>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </div>

        {currentStep !== steps.length && (
          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default App;

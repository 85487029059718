import { format } from "date-fns";
import { config } from "../helpers/constants";
import { CategoriesModel } from "../models/Categories";
import { ServicesModel } from "../models/Services";

export const openLink = (url: string, newTab: boolean): void => {
  newTab
    ? window.open(url, "_blank", "noopener,noreferrer")
    : (window.location.href = url);
};

export async function fetchToken() {
  const res = await fetch(
    `${config.url.API_URL}/o/oauth2/token?refreshToken=${process.env.REACT_APP_REFRESH_TOKEN}`
  );
  const resData = await res.json();
  return resData.data?.token.access_token;
}

export async function fetchServices({
  queryKey,
}: any): Promise<ServicesModel[]> {
  const [, token] = queryKey;

  const res = await fetch(`${config.url.API_URL}/bookingapi/services`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const servicesData = await res.json();
  const staffKey = config.staffKeyManolo;
  return servicesData.data?.services.filter((service: ServicesModel) =>
    service.staff_keys.some((item) => item === staffKey)
  );
}

export async function fetchCategories({
  queryKey,
}: any): Promise<CategoriesModel[]> {
  const [, token] = queryKey;

  const res = await fetch(
    `${config.url.API_URL}/bookingapi/services/categories`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const catData = await res.json();
  return catData.data?.service_categories;
}

export async function fetchBookingSlots({ queryKey }: any) {
  const [, token, selectedDay, selectedServiceKey] = queryKey;

  const payload = {
    staff_key: config.staffKeyManolo,
    service_key: selectedServiceKey,
    selected_date: format(selectedDay, "dd/MM/yyyy"),
    off_hours: false,
    double_booking: false,
    slot_limit: 30,
  };

  const res = await fetch(`${config.url.API_URL}/bookingapi/slots`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  const SlotsData = await res.json();
  return SlotsData?.data?.slots;
}

export async function createCustomer({ queryKey }: any): Promise<string> {
  const [, token, customer] = queryKey;

  const create = await fetch(
    `${config.url.API_URL}/bookingapi/customer/create`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(customer),
    }
  );

  const response = await create.json();

  return response.data?.customer?.key;
}

export async function createAppointment({ queryKey }: any): Promise<any> {
  const [, token, payload] = queryKey;

  if (token) {
    sessionStorage.setItem("appointment", "true");

    const create = await fetch(
      `${config.url.API_URL}/bookingapi/appointment/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await create.json();
    return data;
  }
}

export async function processPayment({ queryKey }: any) {
  const [, paymentToken, payload] = queryKey;

  const rawResponse = await fetch(
    `${config.tilopayURL.API_URL}/processPayment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${paymentToken}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    }
  );

  const content = await rawResponse.json();
  return content?.url;
}

import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import createCartSlice from "./slices/createCartSlice";

export type Item = {
  id: string;
  name: string;
  price: number;
  amount: number;
  duration: number | undefined;
};

export type BookingInfo = {
  selectedDay: Date;
  day: string;
  slot: string;
};

export type CartStore = {
  items: Item[];
  totalAmount: number;
  bookingInfo: BookingInfo;
  addItem: (item: Item) => void;
  addBooking: (booking: BookingInfo) => void;
  removeItem: (id: string) => void;
};

const cartStore = (set: any) =>
  ({
    ...createCartSlice(set),
  } as CartStore);

const useCartStore = create(
  devtools(
    persist(cartStore, {
      name: "global",
    })
  )
);

export default useCartStore;
